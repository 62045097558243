import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OperatorForOperatorService {

  readonly #http = inject(HttpClient);
  readonly #baseUrlPath = `${Config.apiBaseUrl}/operator-for-operator`;

  public emptyQueueOperatorsForOperators (): Observable<void> {
    return this.#http.get<void>(`${this.#baseUrlPath}/empty-queue`);
  }

}
