import { inject, Injectable } from '@angular/core';
import { environment as Config } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketStatesDTO } from '../ticket-states.model';

@Injectable({
  providedIn: 'root'
})
export class TicketStatesService {
  private readonly baseUrlPath = `${Config.apiBaseUrl}/ticket/states`;
  private readonly http = inject(HttpClient);

  public getAllTicketStates (includeClosed: boolean): Observable<TicketStatesDTO[]> {
    const params = new HttpParams().appendAll({ includeClosed });
    return this.http.get<TicketStatesDTO[]>(this.baseUrlPath, { params });
  }

  public getTicketStateById (id: number): Observable<TicketStatesDTO> {
    return this.http.get<TicketStatesDTO>(`${this.baseUrlPath}/${id}`);
  }
  public createTicketState ( ticketState: Partial<TicketStatesDTO> ): Observable<TicketStatesDTO> {
    return this.http.post<TicketStatesDTO>(this.baseUrlPath, ticketState);
  }

  public updateTicketState ( ticketState: TicketStatesDTO ): Observable<TicketStatesDTO> {
    return this.http.patch<TicketStatesDTO>(`${this.baseUrlPath}/${ticketState.id}`, ticketState);
  }

  public deleteTicketState (id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrlPath}/${id}`);
  }
}



