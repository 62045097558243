import { inject, Injectable } from '@angular/core';
import { environment as Config } from '../../../../environments/environment';
import { filter, map, mergeMap, Observable, toArray } from 'rxjs';
import { ActivationDTO, ContractDetailDTO, ContractDTO, TagLicenseExceptionCause } from '../license.model';
import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { translate } from '@jsverse/transloco';
import { CrudOperation, Nullable } from '../../../shared/models/types';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { dateToUTC, endingOfDay, UTCToDate } from '../../../shared/utility/date-utils';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  readonly #basePath = `${Config.apiLicenseBaseUrl}`;
  readonly #http = inject(HttpClient);

  public getAllContractDetailByClientCode (clientCode: string): Observable<ContractDetailDTO[]> {
    return this.#http.get<ContractDetailDTO[]>(`${this.#basePath}/contract/detail/client-code/${clientCode}`).pipe(
      mergeMap(x => x),
      this.mapExpirationDate,
      toArray()
    );
  }

  public getContractDetailById (id: string): Observable<ContractDetailDTO> {
    return this.#http.get<ContractDetailDTO>(`${this.#basePath}/contract/detail/${id}`).pipe(this.mapExpirationDate);
  }

  public updateContractDetail (detail: ContractDetailDTO): Observable<ContractDetailDTO> {
    return this.#http.patch<ContractDetailDTO>(`${this.#basePath}/contract/detail/${detail.id}`, this.setExpireDate(detail)).pipe(this.mapExpirationDate);
  }

  public getByClientCode (clientCode: string): Observable<ContractDTO> {
    return this.#http.get<ContractDTO>(`${this.#basePath}/contract/client-code/${clientCode}`).pipe(this.mapLicenseDeadline);
  }

  public updateContract (id: string, contract: ContractDTO): Observable<ContractDTO> {
    return this.#http.patch<ContractDTO>(`${this.#basePath}/contract/${id}`, this.setExpireDate(contract)).pipe(this.mapLicenseDeadline);
  }

  public getAllActivationsByContractId (id: string): Observable<ActivationDTO[]> {
    return this.#http.get<ActivationDTO[]>(`${this.#basePath}/contract/detail/${id}/activation`).pipe(
      mergeMap(x => x),
      this.mapExpirationDate,
      toArray()
    );
  }

  public getActivationById (contractId: string, activationId: string): Observable<ActivationDTO> {
    return this.#http.get<ActivationDTO>(`${this.#basePath}/contract/detail/${contractId}/activation/${activationId}`).pipe(this.mapExpirationDate);
  }

  public addActivation (contractId: string, activation: Partial<ActivationDTO>): Observable<ActivationDTO> {
    return this.#http.post<ActivationDTO>(`${this.#basePath}/contract/detail/${contractId}/activation`, this.setExpireDate(activation)).pipe(this.mapExpirationDate);
  }

  public addMultipleActivation (contractId: string, activation: Partial<ActivationDTO>[]): Observable<ActivationDTO[]> {
    return this.#http.post<ActivationDTO[]>(`${this.#basePath}/contract/detail/${contractId}/activation/massive`, activation.map(this.setExpireDate)).pipe(
      mergeMap(x => x),
      this.mapExpirationDate,
      toArray()
    );
  }

  public updateActivation (contractId: string, activation: ActivationDTO): Observable<ActivationDTO> {
    return this.#http.patch<ActivationDTO>(`${this.#basePath}/contract/detail/${contractId}/activation/${activation.id}`, this.setExpireDate(activation)).pipe(this.mapExpirationDate);
  }

  public deleteActivation (contractId: string, activationId: string): Observable<void> {
    return this.#http.delete<void>(`${this.#basePath}/contract/detail/${contractId}/activation/${activationId}`);
  }

  // Restituisce la lista degli username che hanno una licenza assegnata (di tipo utente)
  public getAllAssignedUsers (clientCode: string): Observable<string[]> {
    const params = new HttpParams().appendAll({ clientCode });
    return this.#http.get<string[]>(`${this.#basePath}/contract/detail/activation/users`, { params });
  }

  public activateLicense (id: string): Observable<ActivationDTO> {
    return this.#http.patch<ActivationDTO>(`${this.#basePath}/contract/detail/activation/${id}/active`, null).pipe(this.mapExpirationDate);
  }

  public activationErrorToHttpStatus (err: HttpErrorResponse, action: CrudOperation): {
    httpStatus: HttpStatus,
    isConfirm: boolean
  } {
    const httpStatus: HttpStatus = {
      type: 'error',
      text: translate(`crudLicenseGenericErrors.${action}`)
    };
    let isConfirm = false;
    if (err.status === HttpStatusCode.Forbidden) {
      httpStatus.type = 'warning';
      const message: Nullable<TagLicenseExceptionCause> = (err.error as HttpErrorResponse).message as TagLicenseExceptionCause;
      httpStatus.text = translate(`tagLicenseExceptionCause.${message}`);
    } else if (err.status === HttpStatusCode.Conflict && action === 'Update') {
      httpStatus.type = 'warning';
      httpStatus.text = translate('errorUpdateActivationConflict');
      isConfirm = true;
    }
    return { httpStatus, isConfirm };
  }

  public contractErrorToHttpStatus (err: HttpErrorResponse): HttpStatus {
    const httpStatus: HttpStatus = {
      type: 'error',
      text: translate('errorSavingContractDetail')
    };
    if (err.status === HttpStatusCode.Forbidden) {
      httpStatus.type = 'warning';
      let message: Nullable<TagLicenseExceptionCause> = (err.error as HttpErrorResponse).message as TagLicenseExceptionCause;
      if (message === 'ContractDetailExpired') {
        // In questo caso faccio l'override per il messagio di errore in quanto il ContractDetailExpired è già utilizzato per un altro messaggio di errore mostrato per le attivazioni.
        message = 'ContractExpired';
      }
      httpStatus.text = translate(`tagLicenseExceptionCause.${message}`);
    }
    return httpStatus;
  }

  private setExpireDate (b: Partial<ActivationDTO | ContractDTO | ContractDetailDTO>): Partial<ActivationDTO | ContractDTO | ContractDetailDTO> {
    const c = cloneDeep(b);
    const endingOfDayFn = (date: Nullable<string | Date>): Nullable<string | Date> => date ? endingOfDay(dayjs(date).toDate()) : date;
    if('expirationDate' in c) {
      c.expirationDate = endingOfDayFn(c.expirationDate);
      c.expirationDate = dateToUTC(c.expirationDate);

    }
    if('licenseDeadline' in c) {
      const date = endingOfDayFn(c.licenseDeadline);
      if(date) {
        c.licenseDeadline = dateToUTC(date);
      }
    }
    return c;
  }

  private readonly mapExpirationDate = map(<T>(value: T & { expirationDate: Nullable<string | Date> }): T => ({ ...value, expirationDate: UTCToDate(value.expirationDate) }));

  private readonly mapLicenseDeadline = map(<T>(value: T & { licenseDeadline: string | Date }): T => ({ ...value, licenseDeadline: UTCToDate(value.licenseDeadline) }));

}
